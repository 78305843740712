import DocumentModel from "@/models/documentModel";
import router from "../router/index";
import documentService from "@/services/documentService";
import Results from "@/models/resultModel";


const convertToTime = (time: number) => {

  const formatDigit = (number: number) => {
    return number < 10 ? "0" + number : number;
  }

  const hours = Math.trunc(time / (60 * 60 * 1000));
  time -= hours * 60 * 60 * 1000;
  const mins = Math.trunc(time / (60 * 1000));
  time -= mins * 60 * 1000;
  const sec = Math.trunc(time / 1000);
  return (
    formatDigit(hours) +
    "h " +
    formatDigit(mins) +
    "m " +
    formatDigit(sec) +
    "s"
  );
}




const updateDocumentTime = (toReadList: Array < DocumentModel > ) => {


  toReadList.forEach((item) => {
    item.createdSince += 1000;
    item.status =
      item.createdSince / (60 * 60 * 1000) > 4 ? "error" : "success";
  });

  return toReadList;
}

const navigateTo = (fkDocumentId: string) => {
  router.push({
    path: "documents/" + fkDocumentId
  });
}

const loadDocuments = () => {
  let toReadList: Array < DocumentModel > ;
  let searching: boolean;
  let isQueueEmpty: boolean;
  const load = async () => {
    const result: Results < DocumentModel > = await documentService
      .getCurrentUserDocuments();
    // .then((result: Results < DocumentModel > ) => {
    toReadList = result.data;
    searching = false;
    // console.log(result);
    isQueueEmpty = toReadList.length == 0;
    //this function setTime is used to refresh the time every 1s
    // setInterval(() => {
    //   toReadList = updateDocumentTime(toReadList);
    //   // this.updateDocumentTime();
    // }, 1000);
    // documentService.subscribeToNotification()
    //   .then(res => console.log(res))
    //   .catch(err => console.log(err));
    // });
    return {
      toReadList,
      searching,
      isQueueEmpty
    }
  }
  return {
    load
  }
}

export {
  convertToTime,
  updateDocumentTime,
  navigateTo,
  loadDocuments
}