























  import {
    Vue
  } from "vue-property-decorator";
  import DocumentModel from "@/models/documentModel";

  import SvgIcon from "@/components/SvgIcon.vue";
  import Menu from "@/components/Menu.vue";
  import {
    convertToTime,
    updateDocumentTime,
    navigateTo,
    loadDocuments
  } from "@/composables/toReadListMethods"

  export default Vue.extend({
    components: {
      SvgIcon,
      Menu,
    },
    data() {
      return {
        convertToTime,
        updateDocumentTime,
        navigateTo,
        counterInterval :0, 
        searching: true,
        toReadList: [] as Array < DocumentModel > ,
        isQueueEmpty: false,
      };
    },
 created: function(){
    this.counterInterval=  setInterval(() => {
        this.toReadList = updateDocumentTime(this.toReadList);
      }, 1000);
 }
,
 destroyed: function(){
   clearInterval(this.counterInterval)
 }
    ,
    async mounted() {
      const {
        toReadList,
        searching,
        isQueueEmpty
      } = await loadDocuments().load();
      this.toReadList = toReadList;
      this.searching = searching;
      this.isQueueEmpty = isQueueEmpty;
      
    },
  });
